<template>
  <div style="position:relative;z-index:19;display: flex;justify-content: center;align-items: center;padding: 5px 0;">
    <div  @click="openMenu"  style="flex:1;display: flex;flex-direction: column;align-items: center;justify-content: center;">
      <div><v-icon :color="`${menu.active?'#007db5':'#7f8385'}`">{{menu.icon}}</v-icon></div>
    </div>
    <transition name="fadeWidth" mode="out-in">
      <div class="sidebar__popup" v-if="showMenu">
        <Sidebar style="background: #fff;padding:10px 20px 50px;" @closeSidebar="showMenu = false"/>
      </div>
    </transition>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";

export default {
  name: "MenuIcon",
  components:{
    Sidebar,
  },
  data(){
    return{
      menu:{
        id:2,
        name:"Menu",
        icon:"mdi-menu",
        link:"",
        active:false
      },
      showMenu:false,
    }
  },
  methods:{
    openMenu(){
      this.showMenu = !this.showMenu;
    }
  }
}
</script>

<style scoped lang="scss">
.sidebar__popup{
  transition: all 0.3s ease;
  width:100vw;
  left:0;
  z-index:999;
  position:fixed;top:0;
}
.fadeWidth-enter-active,
.fadeWidth-leave-active {
  transition: left 1s;
  left:0;
  overflow: hidden;
}
.fadeWidth-enter,
.fadeWidth-leave-to {
  overflow: hidden;
  transition:left 1s;
  left: -100vw;
}
</style>
